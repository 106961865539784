import { User } from "next-auth";
import { getSession, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import * as React from "react";
import "regenerator-runtime/runtime";

import { FullyCenteredLoader } from "../../components/common/Loader";

type UserContextType = {
  userInfo: Object;
  setUserInfo: (_: Object) => void;
};
export const UserContext = React.createContext<UserContextType | null>(null);

export const UserWrapper = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};

const defaultPublicUser: User = {
  id: -1,
  type: "customer",
  username: "",
  accessToken: "",
  "https://hasura.io/jwt/claims": {},
  iat: 0,
};

export const getUser = () => {
  const { data: session } = useSession();
  return session?.user?.user ?? defaultPublicUser;
};

export const getUserOrLoading = (): [User, false] | [null, true] => {
  const { data: session, status } = useSession();
  const loading = status === "loading";

  if (loading) {
    return [null, true];
  }

  const theUser = session?.user?.user ?? defaultPublicUser;

  return [theUser, false];
};

export function useUserAuthenticationTokenOrLoading():
  | [string | undefined, false]
  | [undefined, true] {
  const { data: session, status } = useSession();
  const loading = status === "loading";

  if (loading) {
    return [undefined, true];
  }

  const accessToken = session?.user?.user?.accessToken;
  return React.useMemo(() => [accessToken, loading], [accessToken, loading]);
}

export function useUserAuthenticationHeaderOrLoading(): [
  Record<string, string>,
  boolean
] {
  const [token, loading] = useUserAuthenticationTokenOrLoading();

  return React.useMemo(() => {
    if (token) {
      return [{ Authorization: `Bearer ${token}` }, loading];
    } else {
      return [{}, loading];
    }
  }, [token, loading]);
}

export function PreloadClient() {
  const [user, loading] = getUserOrLoading();
  return null;
}

export function RequireClientLogin({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, loading] = getUserOrLoading();
  const router = useRouter();

  React.useEffect(() => {
    if (!loading) {
      if (user.type !== "client") {
        router.push("/");
      }
    }
  }, [user]);

  if (loading) {
    return <FullyCenteredLoader />;
  }

  return children;
}

export async function getClientAuthorizationHeader() {
  const accessToken = await getClientAccessToken();
  const authToken = accessToken ? "Bearer " + accessToken : null;
  return authToken;
}

export async function getClientAccessToken() {
  const session = await getSession();
  // @ts-ignore
  const accessToken = session?.user?.user?.accessToken;
  return accessToken;
}

export async function getUserHeaders() {
  const authHeader = await getClientAuthorizationHeader();
  if (authHeader) {
    return { Authorization: authHeader };
  }

  return {};
}

export function RequireUser({
  children,
  fallback,
}: {
  children: React.ReactNode;
  fallback: React.ReactNode;
}) {
  const [, loading] = getUserOrLoading();
  if (loading) {
    return <>{fallback}</>;
  } else {
    return <>{children}</>;
  }
}
