import cx from "classnames";
import * as React from "react";

export const StyleHelper = ({
  children,
  extraClassNames,
  isSpan = false,
}: {
  children: React.ReactNode;
  extraClassNames: Array<string>;
  isSpan?: boolean;
}) => {
  const classNames = cx(extraClassNames);

  if (isSpan) {
    return <span className={classNames}>{children}</span>;
  } else {
    return <div className={classNames}>{children}</div>;
  }
};
