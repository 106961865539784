import * as React from "react";

const cx = require("classnames");

export function Container({
  children,
  isFluid = false,
  isSidePadded = false,
}: {
  children: React.ReactNode;
  isFluid?: boolean;
  modifiers?: string[];
  isSidePadded?: boolean;
}) {
  return (
    <div
      className={cx(
        "tw-container",
        "tw-mx-auto",
        { "tw-p-4 md:tw-p-8": !isFluid && !isSidePadded },
        { "tw-px-4 md:tw-px-8": !isFluid && isSidePadded }
      )}
    >
      {children}
    </div>
  );
}
