"use client";

import { ExclamationIcon, RefreshIcon } from "@heroicons/react/outline";
import cx from "classnames";
import * as React from "react";
import { CSSProperties } from "react";

import { useOpacityFadeIn } from "../../utilities/hooks/useOpacityFadeIn";
import { forceRefreshWebPage } from "../forceRefreshWebPage";
import { Container } from "./Container";
import Spacer from "./Spacer";
import { Text } from "./typography/Text";

export const LoaderLight = ({
  text,
  offerHelp = true,
}: {
  text?: string | JSX.Element;
  offerHelp?: boolean;
}) => <Loader text={text || ""} offerHelp={offerHelp} />;

export const LoaderInline = LoaderLight;

function OfferHelper() {
  return (
    <div>
      <Spacer />
      <DelayDisplay delay={10000}>
        <div>
          Still not loaded? Try{" "}
          <a href="." onClick={forceRefreshWebPage}>
            {" "}
            <strong>reloading</strong>{" "}
          </a>{" "}
          the page.
        </div>
      </DelayDisplay>
      <Spacer />
      <DelayDisplay delay={30000}>
        <div className="tw-flex tw-items-center tw-w-full">
          <ExclamationIcon className="tw-w-5 tw-h-5 tw-mr-2" /> If you're still
          having trouble, call on{" "}
          <strong className="tw-ml-1">+44 117 990 2973</strong>
        </div>
      </DelayDisplay>
    </div>
  );
}

function LoaderStyle() {
  return (
    <style jsx global>{`
      .ldsEllipsis {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 16px;
      }
      .ldsEllipsis div {
        position: absolute;
        top: 3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #666;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .ldsEllipsis div:nth-child(1) {
        left: 6px;
        animation: ldsEllipsis1 0.6s infinite;
      }
      .ldsEllipsis div:nth-child(2) {
        left: 6px;
        animation: ldsEllipsis2 0.6s infinite;
      }
      .ldsEllipsis div:nth-child(3) {
        left: 26px;
        animation: ldsEllipsis2 0.6s infinite;
      }
      .ldsEllipsis div:nth-child(4) {
        left: 45px;
        animation: ldsEllipsis3 0.6s infinite;
      }
      @keyframes ldsEllipsis1 {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes ldsEllipsis3 {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      @keyframes ldsEllipsis2 {
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(19px, 0);
        }
      }
    `}</style>
  );
}
const classes = {
  ldsEllipsis: "ldsEllipsis",
  miniLoaderTimeout: "",
  veryMiniLoaderTimeout: "",
  timeoutFirst: "",
  timeoutSecond: "",
};

export function Loader({
  text,
  offerHelp = true,
}: {
  text?: string | JSX.Element;
  offerHelp?: boolean;
}) {
  return (
    <>
      <LoaderStyle />
      <VeryShortDelayDisplay>
        <div>
          <div>
            <div className={classes.ldsEllipsis}>
              <div />
              <div />
              <div />
              <div />
            </div>
            <br />
            {text}
            {offerHelp ? <OfferHelper /> : null}
          </div>
        </div>
      </VeryShortDelayDisplay>
    </>
  );
}

export function TinyLoader({ style = {} }: { style?: CSSProperties }) {
  return (
    <VeryShortDelayDisplay>
      <div className={classes.ldsEllipsis} style={{ ...style }}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </VeryShortDelayDisplay>
  );
}

export function DelayDisplay({
  children,
  delay,
}: {
  children: React.ReactNode;
  delay: number;
}) {
  return (
    <div
      className={cx(
        "tw-transition-opacity tw-duration-500",
        useOpacityFadeIn(delay)
      )}
    >
      {children}
    </div>
  );
}

export function ShortDelayDisplay({ children }: { children: React.ReactNode }) {
  return <DelayDisplay children={children} delay={1000} />;
}

export function VeryShortDelayDisplay({
  children,
}: {
  children: React.ReactNode;
}) {
  return <DelayDisplay children={children} delay={400} />;
}

export function LoaderIcon() {
  return (
    <div className="tw-w-5 tw-h-5 tw-animate-spin">
      <RefreshIcon className="tw-scale-x-[-1]" />
    </div>
  );
}

export function ComponentOrLoader({
  coreProp,
  loadingText,
  children,
}: {
  coreProp: unknown;
  loadingText: string;
  children: React.ReactNode;
}) {
  return <div>{coreProp ? children : <LoaderLight text={loadingText} />}</div>;
}

export function FullPageLoader({ text }: { text?: string }) {
  return (
    <Container>
      <CenteredLoader text={text} />
    </Container>
  );
}

export function CenteredLoader({ text }: { text?: string | JSX.Element }) {
  return (
    <Text isHCentered>
      <Spacer />
      <Spacer />
      <Spacer />
      <Spacer />
      <LoaderLight text={text} />
    </Text>
  );
}

export function FullyCenteredLoader({
  text,
  offerHelp = true,
}: {
  text?: string;
  offerHelp?: boolean;
}) {
  return (
    <div
      className={
        "tw-flex tw-content-center tw-justify-center tw-flex-wrap tw-w-full tw-h-full tw-text-center"
      }
    >
      <LoaderLight text={text} offerHelp={offerHelp} />
    </div>
  );
}

export function Spinner({
  addMargins,
  textColor = "tw-text-white",
  size = "tw-h-5 tw-w-5",
}: {
  addMargins?: boolean;
  textColor?: string;
  size?: string;
}) {
  return (
    <svg
      className={cx(
        "tw-animate-spin",
        size,
        addMargins ? "tw--ml-1 tw-mr-3" : null,
        textColor
      )}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="tw-opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="tw-opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}
