import * as React from "react";
import { useTimeoutFn } from "react-use";

export function useTrueAfterDelay(delay = 0) {
  const [x, sx] = React.useState(false);
  useTimeoutFn(() => {
    sx(true);
  }, delay);
  return x;
}
