import * as React from "react";

import { StyleHelper } from "../StyleHelper";

export function Text({
  children,
  modifiers = [],
  size,
  isHCentered,
}: {
  children: React.ReactNode;
  modifiers?: (string | number)[];
  size?: string | number;
  isHCentered?: boolean;
}) {
  const extraClassNames: Array<string> = [];

  if (size) {
    extraClassNames.push(sizes[String(size)]);
  }

  for (const modifier of modifiers) {
    const convertedModifier = convertModifiers[modifier];
    if (convertedModifier) {
      extraClassNames.push(convertedModifier);
    }
  }

  if (isHCentered) {
    extraClassNames.push("tw-text-center");
  }

  return (
    <StyleHelper extraClassNames={extraClassNames} isSpan>
      {children}
    </StyleHelper>
  );
}
const sizes: Record<string, string> = {
  "7": "tw-text-xs",
  "6": "tw-text-sm",
  "5": "tw-text-md",
  "4": "tw-text-lg",
  "3": "tw-text-xl",
  "2": "tw-text-2xl",
  "1": "tw-text-3xl",
};

const convertModifiers: Record<string, string> = {
  centered: "tw-text-center",
  uppercase: "tw-uppercase",
  gray: "tw-text-gray-500",
  grey: "tw-text-gray-500",
  danger: "tw-text-red-500",
  primary: "tw-text-green-500",
  success: "tw-text-green-500",
  warning: "tw-text-orange-500",
  info: "tw-text-blue-500",
  white: "tw-text-white",
  italic: "tw-italic",
  "weight-light": "tw-font-light",
  "weight-semibold": "tw-font-semibold",
  "weight-bold": "tw-font-bold",
};
